<template>
  <div class="grid grid-cols-2">
    <div class="min-h-[24px] col-span-2">
      <p
        v-if="product?.productSubscription.discount"
        class="col-span-2 font-bold line-through md:mt-0 text-border-dark ml-2xs"
        :class="[
          {
            'text-right my-sm md:mb-sm': !dialog,
            'text-left': dialog,
          },
        ]"
      >
        {{ n(product?.price?.netto, 'currency') }}
      </p>
    </div>
    <div class="flex flex-col col-span-2">
      <div>
        <p
          class="font-bold font-deco-alt text-primary-base"
          :class="[
            {
              'text-5xl text-right': !dialog,
              'text-2xl text-left': dialog,
            },
          ]"
        >
          {{ n(unitPrice, 'currency') }}
        </p>
      </div>
      <div
        class="flex flex-row-reverse flex-wrap items-center w-full md:flex-row md:flex-nowrap mt-xs"
      >
        <VatShippingHint
          keypath="shop.productList.excludingVAT"
          tag="p"
          classes="w-full ml-auto text-right font-roboto md:w-auto md:hidden md:mb-md"
        />
        <VatShippingHint
          keypath="shop.productList.excludingVAT"
          tag="p"
          classes="hidden w-full ml-auto text-right font-roboto md:w-auto md:block"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { Product } from '~/server/transformers/shop/product/types';
import VatShippingHint from '@/components/shop/atoms/vatShippingHint.vue';

const { n } = useTrans();
const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
    default: {} as Product,
  },
  dialog: {
    type: Boolean,
    required: false,
    default: false,
  },
  quantity: {
    type: Number,
    required: false,
  },
});

// default discount value is 0
const unitPrice =
  props.product?.price?.netto -
  (props.product?.price?.netto / 100) *
    props.product?.productSubscription?.discount;
</script>
